import ApiService from "../services/api.service";
import Vue from "vue";

export default {
  namespace: true,
  state: {
    filterTypes: [],
    filterOptions: {
      villes: [],
    },
    formContact: {},
    filterOption: {
      villes: [],
      quartiers: [],
    },
    regions: [],
  },
  getters: {
    getFilterTypes(state) {
      return state.filterTypes;
    },
    getFilterOptions(state) {
      return state.filterOptions;
    },
    getFilterOption(state) {
      return state.filterOption;
    },
    getFormContact(state) {
      return state.formContact;
    },
    getFilterRegion(state) {
      return state.regions;
    },
  },
  actions: {
    FILTER_DATA(context) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get("ad/filtered")
            .then((response) => {
              resolve(response.data);
              context.commit("setFilterTypes", response.data.types);
              context.commit("setFilterOption", response.data.option);
              context.commit("setFilterOptions", response.data.option);
              context.commit("setFilterRegions", response.data.regions);
            })
            .catch((error) => {
              Vue.$toast.open({
                message:
                  "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                type: "error",
              });
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merçi!",
            type: "error",
          });
        }
      });
    },
    CONTACT_CREATE(context) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get("contact/create")
            .then((response) => {
              resolve(response.data.form);
              context.commit("setFormContact", response.data.form);
            })
            .catch((error) => {
              Vue.$toast.open({
                message:
                  "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                type: "error",
              });
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merçi!",
            type: "error",
          });
        }
      });
    },
  },
  mutations: {
    setFilterTypes(state, filterTypes) {
      state.filterTypes = filterTypes;
    },
    setFilterOptions(state, filterOptions) {
      state.filterOptions = filterOptions;
    },
    setFormContact(state, formContact) {
      state.formContact = formContact;
    },
    setFilterOption(state, filterOption) {
      state.filterOption = filterOption;
    },
    setFilterRegions(state, regions) {
      state.regions = regions;
    },
  },
};
