import ApiService from "../services/api.service";
import Vue from "vue";

const actions = {
  contact(context, payload) {
    return new Promise((resolve, reject) => {
      if (navigator.onLine) {
        ApiService.post("contact", payload)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        Vue.$toast.open({
          message:
            "Connexion indisponible, veuillez réessayer plus tard. Merçi!",
          type: "error",
        });
      }
    });
  },
  rejectVisitRequest(context, payload) {
    return new Promise((resolve, reject) => {
      if (navigator.onLine) {
        ApiService.put(`auth/visit/${payload.id}/reject`, {
          motif: payload.value,
        })
          .then((response) => {
            context.dispatch("myListAnnonces");
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        Vue.$toast.open({
          message:
            "Connexion indisponible, veuillez réessayer plus tard. Merçi!",
          type: "error",
        });
      }
    });
  },
  rejectOfferRequest(context, payload) {
    return new Promise((resolve, reject) => {
      if (navigator.onLine) {
        ApiService.put(`auth/propsImmo/${payload.id}/reject`, {
          motif: payload.value,
        })
          .then((response) => {
            context.dispatch("myListAnnonces");
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        Vue.$toast.open({
          message:
            "Connexion indisponible, veuillez réessayer plus tard. Merçi!",
          type: "error",
        });
      }
    });
  },
  programVisitRequest(context, payload) {
    return new Promise((resolve, reject) => {
      if (navigator.onLine) {
        ApiService.put(`auth/visit/${payload.id}/program`, {
          date_prevue: payload.date_prevue,
          heure_prevue: payload.heure_prevue,
        })
          .then((response) => {
            context.dispatch("myListAnnonces");
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        Vue.$toast.open({
          message:
            "Connexion indisponible, veuillez réessayer plus tard. Merçi!",
          type: "error",
        });
      }
    });
  },
  endVisitRequest(context, payload) {
    return new Promise((resolve, reject) => {
      if (navigator.onLine) {
        ApiService.get(`auth/visit/${payload.id}/end`)
          .then((response) => {
            context.dispatch("myListAnnonces");
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        Vue.$toast.open({
          message:
            "Connexion indisponible, veuillez réessayer plus tard. Merçi!",
          type: "error",
        });
      }
    });
  },
  acceptOfferRequest(context, payload) {
    return new Promise((resolve, reject) => {
      if (navigator.onLine) {
        ApiService.get(`auth/propsImmo/${payload.id}/accept`)
          .then((response) => {
            context.dispatch("myListAnnonces");
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        Vue.$toast.open({
          message:
            "Connexion indisponible, veuillez réessayer plus tard. Merçi!",
          type: "error",
        });
      }
    });
  },
};

export default actions;
