import ApiService from "../services/api.service";
import Vue from "vue";

export default {
  namespace: true,
  state: {
    annonces: [],
    annonce: {
      type: {},
      ville: {},
      quartier: {},
      images: [],
      videos: [],
      documents: [],
    },
    rentAnnonces: [],
    sellAnnonces: [],
    lastAnnonces: [],
    image: {},
    formAnnonce: {
      ville: {
        id: "",
        quartiers: [],
      },
    },
    annonceOptions: {
      villes: [],
      quartiers: [],
    },
    annonceTags: [],
    annonceTypes: [],
    listAnnonces: [],
  },
  getters: {
    getLastAnnonces(state) {
      return state.lastAnnonces;
    },
    getAnnonces(state) {
      return state.annonces;
    },
    GET_ANNONCE_IMAGE(state) {
      return state.image;
    },
    getAnnonce(state) {
      return state.annonce;
    },
    getFormAnnonce(state) {
      return state.formAnnonce;
    },
    getAnnonceOptions(state) {
      return state.annonceOptions;
    },
    getAnnonceTags(state) {
      return state.annonceTags;
    },
    getAnnonceTypes(state) {
      return state.annonceTypes;
    },
    getListAnnonces(state) {
      return state.listAnnonces;
    },
  },
  actions: {
    ALL_ANNONCES(context) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get(`ad`)
            .then(({ data }) => {
              resolve(data.annonces);
              context.commit("setAnnonces", data.annonces);
            })
            .catch((error) => {
              reject(error);
              Vue.$toast.open({
                message:
                  "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                type: "error",
              });
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merçi!",
            type: "error",
          });
        }
      });
    },
    SINGLE_ANNONCE(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get(`ad/${payload}`)
            .then(({ data }) => {
              resolve(data.annonce);
              context.commit("setAnnonce", data.annonce);
              context.commit("SET_COVER_IMAGE", data.annonce.images[0]);
            })
            .catch((error) => {
              Vue.$toast.open({
                message:
                  "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                type: "error",
              });
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merçi!",
            type: "error",
          });
        }
      });
    },
    LAST_ANNONCES(context) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get(`ad/latest`)
            .then((response) => {
              resolve(response.data.ads);
              context.commit("setLastAnnonces", response.data.ads);
            })
            .catch((error) => {
              Vue.$toast.open({
                message:
                  "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                type: "error",
              });
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merçi!",
            type: "error",
          });
        }
      });
    },
    createAnnonce(context) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get(`auth/ad/create`)
            .then((response) => {
              resolve(response.data);
              context.commit("setFormAnnonce", response.data.form);
              context.commit("setAnnonceOptions", response.data.option);
              context.commit("setAnnonceTags", response.data.tags);
              context.commit("setAnnonceTypes", response.data.types);
            })
            .catch((error) => {
              reject(error);
              Vue.$toast.open({
                message:
                  "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                type: "error",
              });
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merçi!",
            type: "error",
          });
        }
      });
    },
    editAnnonce(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get(`auth/ad/${payload}/edit`)
            .then((response) => {
              resolve(response.data);
              context.commit("setFormAnnonce", response.data.form);
              context.commit("setAnnonceOptions", response.data.option);
              context.commit("setAnnonceTags", response.data.tags);
              context.commit("setAnnonceTypes", response.data.types);
            })
            .catch((error) => {
              reject(error);
              Vue.$toast.open({
                message:
                  "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                type: "error",
              });
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merçi!",
            type: "error",
          });
        }
      });
    },
    storeAnnonce(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.post(`auth/ad`, payload.form, payload.config)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
              Vue.$toast.open({
                message:
                  "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                type: "error",
              });
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merçi!",
            type: "error",
          });
        }
      });
    },
    updateAnnonce(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.post(`auth/ad/${payload.id}`, payload.form, payload.config)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
              Vue.$toast.open({
                message:
                  "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                type: "error",
              });
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merçi!",
            type: "error",
          });
        }
      });
    },
    myListAnnonces(context) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get(`auth/liste-de-mes-annonces`)
            .then((response) => {
              resolve(response);
              context.commit("setListAnnonces", response.data.data);
            })
            .catch((error) => {
              reject(error);
              Vue.$toast.open({
                message: "Attention! " + error.response.data.message,
                type: "warning",
              });
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merçi!",
            type: "error",
          });
        }
      });
    },
  },
  mutations: {
    setAnnonces(state, ads) {
      state.annonces = ads;
    },
    setAnnonce(state, annonce) {
      return (state.annonce = annonce);
    },
    SET_COVER_IMAGE(state, image) {
      return (state.image = image);
    },
    setLastAnnonces(state, lastAd) {
      state.lastAnnonces = lastAd;
    },
    setFormAnnonce(state, formAnnonce) {
      state.formAnnonce = formAnnonce;
    },
    setAnnonceOptions(state, annnonceOptions) {
      state.annonceOptions = annnonceOptions;
    },
    setAnnonceTags(state, annonceTags) {
      state.annonceTags = annonceTags;
    },
    setAnnonceTypes(state, annonceTypes) {
      state.annonceTypes = annonceTypes;
    },
    setListAnnonces(state, listAnnonces) {
      state.listAnnonces = listAnnonces;
    },
  },
};
