import ApiService from "@/store/services/api.service";
import JwtService from "@/store/services/jwt.service";
import Vue from "vue";

export default {
  namespace: true,
  state: {
    isAuthenticated: false,
    error: null,
    token: JwtService.getToken() || null,
    user: {
      roles: [],
    },
    confirmUser: null,
    userNumber: null,
    forgotNumber: null,
    userChangePassword: null,
  },
  getters: {
    isAuthenticated(state) {
      return state.token !== null;
    },
    user(state) {
      return state.user;
    },
    confirmationAccount(state) {
      return state.confirmUser;
    },
    getUserNumber(state) {
      return state.userNumber;
    },
    getForgotNumber(state) {
      return state.forgotNumber;
    },
    getUserChangePassword(state) {
      return state.userChangePassword;
    },
  },
  actions: {
    LOGIN(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.post("login", payload)
            .then((response) => {
              const token = response.data.access_token;
              JwtService.saveToken(token);
              context.commit("SET_TOKEN", token);
              resolve(response);
            })
            .catch((error) => {
              reject(error);
              Vue.$toast.open({
                message:
                  "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                type: "error",
              });
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merçi!",
            type: "error",
          });
        }
      });
    },
    LOGOUT(context) {
      if (context.getters.isAuthenticated) {
        return new Promise((resolve, reject) => {
          if (navigator.onLine) {
            ApiService.setHeader();
            ApiService.get("auth/logout")
              .then((response) => {
                JwtService.destroyToken();
                context.commit("REMOVE_TOKEN");
                resolve(response);
              })
              .catch((error) => {
                reject(error);
                Vue.$toast.open({
                  message:
                    "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                  type: "error",
                });
              });
          } else {
            Vue.$toast.open({
              message:
                "Internet indisponible, veuillez réessayer plus tard. Merçi!",
              type: "error",
            });
          }
        });
      }
    },
    FORGOT_PASSWORD(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.post("forgot/password/sms", payload)
            .then((response) => {
              resolve(response);
              context.commit("SET_FORGOT_NUMBER", payload);
            })
            .catch((error) => {
              reject(error);
              Vue.$toast.open({
                message:
                  "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                type: "error",
              });
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merçi!",
            type: "error",
          });
        }
      });
    },
    REGISTER: ({ commit }, payload) => {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.post("register", payload)
            .then((response) => {
              if (response) {
                commit("SET_USER_CONFIRMATION", response.data.data);
                resolve(true);
              }
            })
            .catch((error) => {
              reject(error);
              Vue.$toast.open({
                message:
                  "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                type: "error",
              });
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merçi!",
            type: "error",
          });
        }
      });
    },
    USER(context) {
      if (context.getters.isAuthenticated) {
        return new Promise((resolve, reject) => {
          if (navigator.onLine) {
            ApiService.post("auth/me")
              .then((response) => {
                context.commit("SET_USER", response.data.data);
                resolve(response);
              })
              .catch((error) => {
                reject(error);
                Vue.$toast.open({
                  message:
                    "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                  type: "error",
                });
              });
          } else {
            Vue.$toast.open({
              message:
                "Internet indisponible, veuillez réessayer plus tard. Merçi!",
              type: "error",
            });
          }
        });
      }
    },
    SEND_BACK(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.post("demande/activation-code", payload)
            .then((response) => {
              if (response) {
                context.commit("SET_USER_NUMBER", payload.telephone_1);
                resolve(true);
              }
            })
            .catch((error) => {
              reject(error);
              Vue.$toast.open({
                message:
                  "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                type: "error",
              });
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merçi!",
            type: "error",
          });
        }
      });
    },
    CONFIRMATION_ACCOUNT(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.post("verification/sms", payload)
            .then((response) => {
              if (response) {
                context.commit("SET_USER_CONFIRMATION", payload);
                resolve(true);
              }
            })
            .catch((error) => {
              reject(error);
              Vue.$toast.open({
                message:
                  "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                type: "error",
              });
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merçi!",
            type: "error",
          });
        }
      });
    },
    VERIFICATION_CODE(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.post("forgot/password/update", payload)
            .then((response) => {
              if (response) {
                context.commit("SET_USER_CHANGE_PASSWORD", payload);
                resolve(true);
              }
            })
            .catch((error) => {
              reject(error);
              Vue.$toast.open({
                message:
                  "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                type: "error",
              });
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merçi!",
            type: "error",
          });
        }
      });
    },
    CHANGE_PASSWORD(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.post("forgot/password/updated", payload)
            .then((response) => {
              if (response) {
                context.commit("SET_USER_CHANGE_PASSWORD", payload);
                resolve(true);
              }
            })
            .catch((error) => {
              reject(error);
              Vue.$toast.open({
                message:
                  "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                type: "error",
              });
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merçi!",
            type: "error",
          });
        }
      });
    },
    refreshToken(context) {
      if (context.getters.isAuthenticated) {
        return new Promise((resolve, reject) => {
          if (navigator.onLine) {
            // JwtService.destroyToken();
            ApiService.get("auth/refresh")
              .then((response) => {
                const token = response.data.access_token;
                JwtService.saveToken(token);
                context.commit("SET_TOKEN", token);
                resolve(response);
              })
              .catch((error) => {
                reject(error);
                Vue.$toast.open({
                  message:
                    "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                  type: "error",
                });
              });
          } else {
            Vue.$toast.open({
              message:
                "Internet indisponible, veuillez réessayer plus tard. Merçi!",
              type: "error",
            });
          }
        });
      }
    },
  },
  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
      state.isAuthenticated = true;
    },
    REMOVE_TOKEN(state) {
      state.token = null;
      state.isAuthenticated = false;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    SET_USER_CONFIRMATION(state, confirmUser) {
      state.confirmUser = confirmUser;
    },
    SET_USER_NUMBER(state, userNumber) {
      state.userNumber = userNumber;
    },
    SET_FORGOT_NUMBER(state, number) {
      state.forgotNumber = number;
    },
    SET_USER_CHANGE_PASSWORD(state, data) {
      state.userChangePassword = data;
    },
  },
};
