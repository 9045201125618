import ApiService from "../services/api.service";
import Vue from "vue";

export default {
  namespace: true,
  state: {
    posts: [],
    lastPosts: [],
    postCategories: [],
    post: {
      user: {},
    },
    media: {},
  },
  getters: {
    getPosts(state) {
      return state.posts;
    },
    getLastPosts(state) {
      return state.lastPosts;
    },
    getPostCategories(state) {
      return state.postCategories;
    },
    getPost(state) {
      return state.post;
    },
    getPostMedia(state) {
      return state.media;
    },
  },
  actions: {
    ALL_POSTS(context) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get("post")
            .then((response) => {
              resolve(response.data.posts);
              context.commit("setPosts", response.data.posts);
            })
            .catch((error) => {
              Vue.$toast.open({
                message:
                  "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                type: "error",
              });
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merçi!",
            type: "error",
          });
        }
      });
    },
    SINGLE_POST(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get(`post/${payload}`)
            .then((response) => {
              resolve(response.data.post);
              context.commit("setPost", response.data.post);
              context.commit("setPostMedia", response.data.post.media);
            })
            .catch((error) => {
              Vue.$toast.open({
                message:
                  "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                type: "error",
              });
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merçi!",
            type: "error",
          });
        }
      });
    },
    LAST_POSTS_CATEGORIES(context) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get("articles")
            .then((response) => {
              resolve(response.data.data);
              context.commit("setLastPosts", response.data.posts);
              context.commit("setPostCategories", response.data.categories);
            })
            .catch((error) => {
              Vue.$toast.open({
                message:
                  "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                type: "error",
              });
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merçi!",
            type: "error",
          });
        }
      });
    },
  },
  mutations: {
    setPosts(state, posts) {
      state.posts = posts;
    },
    setLastPosts(state, lastPosts) {
      state.lastPosts = lastPosts;
    },
    setPostCategories(state, postCategories) {
      state.postCategories = postCategories;
    },
    setPost(state, post) {
      state.post = post;
    },
    setPostMedia(state, media) {
      state.media = media;
    },
  },
};
