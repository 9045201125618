import ApiService from "../services/api.service";
import Vue from "vue";

export default {
  namespace: true,
  state: {
    products: [],
    product: {
      user: {},
      partenaire: {},
    },
    lastProduct: [],
    image: {},
    formProduct: {},
    productTags: [],
    listProducts: [],
  },
  getters: {
    getProducts(state) {
      return state.products;
    },
    getProduct(state) {
      return state.product;
    },
    getProductImage(state) {
      return state.image;
    },
    getLastProducts(state) {
      return state.lastProduct;
    },
    getFormProduct(state) {
      return state.formProduct;
    },
    getProductTags(state) {
      return state.productTags;
    },
    getProductsList(state) {
      return state.listProducts;
    },
  },
  actions: {
    ALL_PRODUCTS(context) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get("product")
            .then((response) => {
              resolve(response.data.products);
              context.commit("setProducts", response.data.products);
            })
            .catch((error) => {
              Vue.$toast.open({
                message:
                  "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                type: "error",
              });
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merçi!",
            type: "error",
          });
        }
      });
    },
    SINGLE_PRODUCT(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get(`product/${payload}`)
            .then((response) => {
              resolve(response.data.product);
              context.commit("setProduct", response.data.product);
              context.commit(
                "setProductImage",
                response.data.product.images[0]
              );
            })
            .catch((error) => {
              Vue.$toast.open({
                message:
                  "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                type: "error",
              });
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merçi!",
            type: "error",
          });
        }
      });
    },
    LAST_PRODUCTS(context) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get("nos-derniers-produits")
            .then((response) => {
              resolve(response.data.latestProduits);
              context.commit("setLastProducts", response.data.latestProduits);
            })
            .catch((error) => {
              Vue.$toast.open({
                message:
                  "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                type: "error",
              });
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merçi!",
            type: "error",
          });
        }
      });
    },
    createProduct(context) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get(`auth/product/create`)
            .then((response) => {
              resolve(response);
              context.commit("setFormProduct", response.data.form);
              context.commit("setProductTags", response.data.tags);
            })
            .catch((error) => {
              reject(error);
              Vue.$toast.open({
                message:
                  "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                type: "error",
              });
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merçi!",
            type: "error",
          });
        }
      });
    },
    editProduct(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get(`auth/product/${payload}/edit`)
            .then((response) => {
              resolve(response);
              context.commit("setFormProduct", response.data.form);
              context.commit("setProductTags", response.data.tags);
            })
            .catch((error) => {
              reject(error);
              Vue.$toast.open({
                message:
                  "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                type: "error",
              });
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merçi!",
            type: "error",
          });
        }
      });
    },
    myListProducts(context) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.get(`auth/product`)
            .then((response) => {
              resolve(response);
              context.commit("setProductsList", response.data.products);
            })
            .catch((error) => {
              reject(error);
              Vue.$toast.open({
                message:
                  "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                type: "error",
              });
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merçi!",
            type: "error",
          });
        }
      });
    },
    storeProduct(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.post(`auth/product`, payload.form, payload.config)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
              Vue.$toast.open({
                message:
                  "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                type: "error",
              });
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merçi!",
            type: "error",
          });
        }
      });
    },
    updateProduct(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.post(
            `auth/product/${payload.id}`,
            payload.form,
            payload.config
          )
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
              Vue.$toast.open({
                message:
                  "Une erreur c'est produit, veuillez réessayer plus tard. Merçi!",
                type: "error",
              });
            });
        } else {
          Vue.$toast.open({
            message:
              "Internet indisponible, veuillez réessayer plus tard. Merçi!",
            type: "error",
          });
        }
      });
    },
    deleteProduct(context, payload) {
      return new Promise((resolve, reject) => {
        if (navigator.onLine) {
          ApiService.delete(`auth/product/${payload}`)
            .then((response) => {
              resolve(response);
              context.dispatch("myListProducts");
            })
            .catch((error) => {
              reject(error);
            });
        } else {
          Vue.$toast.open({
            message:
              "Connexion indisponible, veuillez réessayer plus tard. Merçi!",
            type: "error",
          });
        }
      });
    },
  },
  mutations: {
    setProducts(state, products) {
      state.products = products;
    },
    setProduct(state, product) {
      state.product = product;
    },
    setProductImage(state, image) {
      state.image = image;
    },
    setLastProducts(state, lastProduct) {
      state.lastProduct = lastProduct;
    },
    setFormProduct(state, formProduct) {
      state.formProduct = formProduct;
    },
    setProductTags(state, productTags) {
      state.productTags = productTags;
    },
    setProductsList(state, listProducts) {
      state.listProducts = listProducts;
    },
  },
};
