import auth from "./auth.module";
import search from "./search.module";
import annonce from "./ad.module";
import product from "./product.module";
import town from "./town.module";
import service from "./service.module";
import partner from "./partner.module";
import post from "./post.module";
import create from "./create.module";
import offer from "./offer.module";
import request from "./request.module";
import pubs from "./publicity.module";

const modules = {
  auth,
  search,
  annonce,
  product,
  town,
  service,
  partner,
  post,
  create,
  offer,
  request,
  pubs,
};

export default modules;
